import React, { useEffect, useState } from "react";
import { myfunctions } from "../firebase";
import { Button, Modal, Divider, Loader } from "semantic-ui-react";
import { getAllfromDatabase, UploadToBucket, addValueToDatabase } from "../Component/FirebaseHelperFunctions";
import NewReceiptReceiptOverview from "../Component/Receipt_ReceiptInput";
import NewReceiptItemInputs from "../Component/Receipts_ItemInputs";
import { FormattedDate, FormattedDateTime } from "../Component/AppGlobalFunctions";
import { httpsCallable } from "firebase/functions"

const NewReceipt = ({ openNewReceiptModal, setOpenNewReceiptModal, fetchReceipts, }) => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    const newItem = {
        itemName: "",
        qty: "",
        itemPrice: "",
        details: "",
        totalPrice: "",

    };

    const newReceipt = {
        companyName: "",
        receiptDate: formattedDate,
        cashAmount: "",
        giftCardAmount: "",
        totalAmount: "",
        itemsTotal: "",
        deliveryMethod: "",
        notes: [],
        fileList: [],
        status: "",
        flag: false,
        taxDeffered: false,
        transactions: [],
    }

    const [newReceiptData, setNewReceiptData] = useState({
        receipt: newReceipt,
        items: []
    });


    const [companyOptions, setCompanyOptions] = useState([]);
    const [deliveryMethodOptions, setDeliveryMethodOptions] = useState([]);
    const [itemOptions, setItemOptions] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);


    useEffect(() => {


        setErrorMessage("");

        const fetchdata = async () => {
            try {
                const tempCompanyOptions = await getAllfromDatabase("CompanyList")
                if (tempCompanyOptions) {
                    setCompanyOptions(tempCompanyOptions);
                } else {
                    setCompanyOptions({});
                }

                const tempDeliveryOptions = await getAllfromDatabase("Config/SupplyDeliveryMethod")
                if (tempDeliveryOptions) {
                    setDeliveryMethodOptions(tempDeliveryOptions);
                } else {
                    setDeliveryMethodOptions({});
                }

                var tempItemOptions = await getAllfromDatabase("ItemsReport");
                tempItemOptions[0] = { "item-name": "--Other Business Expense" }
                tempItemOptions[1] = { "item-name": "--Shipping Expense (ex. PirateShip)" }
                tempItemOptions[2] = { "item-name": "--Packing Expense (ex. Boxes)" } //Adding Other Business expense to the items option list to allow for manual input of non COGS expenses
                tempItemOptions[4] = { "item-name": "--Items will Not Be Listed" }
                setItemOptions(tempItemOptions);

            } catch (error) {
                setErrorMessage("Error fetching data:", error);
            }
        };

        fetchdata();

    }, []);

    const handleAddItem = () => {
        setNewReceiptData({ ...newReceiptData, items: [...newReceiptData.items, newItem] });
    };

    const handleDeleteItem = (index) => {
        const updatedItems = [...newReceiptData.items];
        updatedItems.splice(index, 1);
        setNewReceiptData({ ...newReceiptData, items: updatedItems });
    };

    const handleSubmit = async () => {
        const updatedReceipt = newReceiptData.receipt
        setErrorMessage("")
        let submitError = ""

        // Check if all required fields are filled
        if (newReceiptData.items.length == 0) { submitError = "Please enter item details" }
        if (newReceiptData.receipt.fileList.length == 0) { submitError = "Please upload receipt image or PDF" }
        if (!newReceiptData.receipt.deliveryMethod) { submitError = "Please select delivery method" }
        if (!newReceiptData.receipt.totalAmount) { submitError = "Please enter Receipt Cash/Gift card amount" }
        if (!newReceiptData.receipt.receiptDate) { submitError = "Please select date" }
        if (!newReceiptData.receipt.companyName) { submitError = "Please select company" }

        if (!submitError) {

            // Add date to the name of every file uploaded
            if (updatedReceipt.fileNames) {
                updatedReceipt.fileNames = updatedReceipt.fileNames.map(fileName => FormattedDateTime() + "_" + fileName);
            }
            if (updatedReceipt.notes.length > 0) {
                updatedReceipt.notes[0] = FormattedDateTime() + "-" + newReceiptData.receipt.notes[0];
            }
            const finalReceiptData = { ...newReceiptData, receipt: updatedReceipt }

            try {
                await UploadToBucket("Receipts/", finalReceiptData.receipt.fileList, finalReceiptData.receipt.fileNames)
            } catch (error) {
                setErrorMessage("Error uploading file to bucket:", error)
            }

            try {
                await addValueToDatabase("Receipts/", finalReceiptData);
            } catch (error) {
                setErrorMessage("Error updating database:", error)
            }


            setOpenNewReceiptModal(false);
            setNewReceiptData({
                receipt: newReceipt,
                items: []
            })
            fetchReceipts()
        } else {
            setErrorMessage(submitError)
        }
    }

    const refreshItemList = async () => {
        setLoading(true)
        const updateItems = httpsCallable(myfunctions, "updateItemList")

        await updateItems()
            .then(() => {
                console.log("Function started")
                setLoading(false)
            }).catch((error) => {
                console.log(error)
            })
    }




    return (
        <div>
            <Modal
                size='fullscreen'
                onClose={() => setOpenNewReceiptModal(false)}
                onOpen={() => setOpenNewReceiptModal(true)}
                open={openNewReceiptModal}
                closeOnDimmerClick={false}
                trigger={<Button color="teal" onClick={() => setOpenNewReceiptModal(true)}>Create New Receipt</Button>}
            >
                <Modal.Header>Create New Receipt</Modal.Header>

                <Modal.Content>
                    <NewReceiptReceiptOverview
                        companyOptions={companyOptions}
                        setCompanyOptions={setCompanyOptions}
                        deliveryMethodOptions={deliveryMethodOptions}
                        newReceiptData={newReceiptData}
                        setNewReceiptData={setNewReceiptData}
                    />
                </Modal.Content>

                <Divider horizontal>Items Total: {newReceiptData.receipt.itemsTotal}</Divider>

                <Modal.Content >

                    {newReceiptData.items.map((item, index) => (
                        <NewReceiptItemInputs
                            key={index}
                            newReceiptData={newReceiptData}
                            index={index}
                            setNewReceiptData={setNewReceiptData}
                            itemNameOptions={itemOptions}
                            handleDeleteItem={() => handleDeleteItem(index)
                            }
                        />
                    ))}


                    <Button icon="plus" content="Add Item" color="teal" onClick={handleAddItem} style={{ marginTop: "10px" }} size="tiny" />

                </Modal.Content>

                <Modal.Actions>
                    <p style={{ color: "red" }}>{errorMessage}</p>
                    <Button content="Update Item List" icon="refresh" onClick={refreshItemList} loading={loading} />
                    <Button content="Close" onClick={() => {
                        setNewReceiptData({
                            receipt: newReceipt,
                            items: []
                        })
                        setOpenNewReceiptModal(false)
                    }} />
                    <Button
                        content="Create Receipt"
                        labelPosition='right'
                        icon='checkmark'
                        color="teal"
                        onClick={handleSubmit}
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
}

export default NewReceipt;
