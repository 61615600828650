import React, { useState } from "react";
import { Dropdown, Input } from "semantic-ui-react";
import { addValueToDatabase } from "./FirebaseHelperFunctions";

function SearchableDropdown({ 
    searchable=false, 
    addItem=false, 
    addValueDBPath, 
    icon, 
    inputOptions, 
    setInputOptions,
    inputSubselectionLabel, 
    handleSelection, 
    selected="",
    dropdownLabel, 
    showOnlyUnique = false, 
    sortalphabetic = false 
}) {

    const [searchValue, setSearchValue] = useState("");
    const [newItem, setNewItem] = useState("");
    const [viewSelection, setViewSelection] = useState(selected);

    var itemText = "";

    const uniqueItemNamesSet = new Set();

    const mappedOptions = Object.keys(inputOptions).map(inputId => {
        var item = ''
        if (inputSubselectionLabel) {
            item = inputOptions[inputId][inputSubselectionLabel];
        } else {
            item = inputOptions[inputId];
        }

        if (showOnlyUnique) {
            if (!uniqueItemNamesSet.has(item)) {
                uniqueItemNamesSet.add(item);

                if (item.length >= 50) {
                    const first40Chars = item.substring(0, 40);
                    const last10Chars = item.substring(item.length - 30);
                    itemText = `${first40Chars}...${last10Chars}`;
                } else {
                    itemText = item
                }
                return {
                    key: item,
                    text: itemText,
                    value: item,
                };
            }
        } else {
            if (item.length >= 50) {
                const first40Chars = item.substring(0, 40);
                const last10Chars = item.substring(item.length - 30);
                itemText = `${first40Chars}...${last10Chars}`;
            } else {
                itemText = item
            }
            return {
                key: item,
                text: itemText,
                value: item,
            };
        }

        return null;
    });

    const cleanMappedOptions = mappedOptions.filter(item => item !== null);

    var sortedMappedOptions
    if (sortalphabetic) {
        sortedMappedOptions = cleanMappedOptions.sort((a, b) => {
            return a.text.localeCompare(b.text);

        });
    } else {
        sortedMappedOptions = cleanMappedOptions
    }

    // Convert the sortedMappedData array to a standard JavaScript array
    var filteredSortedMappedOptions
    if (searchable) {
        filteredSortedMappedOptions = sortedMappedOptions.filter(option =>
            option.value.toLowerCase().includes(searchValue.toLowerCase())
        );
    } else {
        filteredSortedMappedOptions = sortedMappedOptions
    }

    var filteredSortedMappedOptionsArray
    if (!Array.isArray(filteredSortedMappedOptions)) {
        filteredSortedMappedOptionsArray = Array.from(filteredSortedMappedOptions)
    } else {
        filteredSortedMappedOptionsArray = filteredSortedMappedOptions
    }
    ;



    return (

        <Dropdown
            text={viewSelection}
            icon={icon}
            placeholder={dropdownLabel}
            floating
            labeled
            button
            className='icon'
        >
            <Dropdown.Menu>

                {searchable && (
                    <Input
                        icon='search'
                        iconPosition='left'
                        className='search'
                        placeholder='Search...'
                        value={searchValue}
                        onChange={(e, { value }) => setSearchValue(value)}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => {
                            if (e.key === ' ') {
                                e.preventDefault(); // Prevent default action for the space key
                            }
                        }}
                        size='small'
                    />
                )}

                {addItem && (
                    <Input
                        iconPosition='right'
                        placeholder='Add...'
                        action={{
                            color: 'teal',
                            content: 'Add',
                            onClick: () => {
                                if (newItem) {
                                    addValueToDatabase(addValueDBPath, { Name: newItem });
                                    let randomID = Math.floor(Math.random() * 1000) + 1
                                    setInputOptions({ ...inputOptions,  [randomID]: {Name: newItem} });
                                    handleSelection(newItem);
                                    setViewSelection(newItem);
                                }
                            }
                        }}
                        value={newItem}
                        onChange={(e, { value }) => setNewItem(value)}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => {
                            if (e.key === ' ') {
                                e.preventDefault(); // Prevent default action for the space key
                            }
                        }}
                        size='small'
                    />
                )}

                <Dropdown.Menu scrolling>
                    {filteredSortedMappedOptionsArray.map(option => {
                        return (
                            <Dropdown.Item key={option.key} {...option} onClick={() => {
                                handleSelection(option.value)
                                setViewSelection(option.value);
                            }
                            }

                            />
                        )

                    })}
                </Dropdown.Menu>
            </Dropdown.Menu>
        </Dropdown>
    )



}

export default SearchableDropdown