import React from "react";

export function FormattedDateTime (){
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date+' '+time;
    return dateTime
}

export function FormattedDate (){
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

export function ShortenItemName (itemName, outputLen=50)  {
    let itemText=""
    if (itemName.length >= outputLen) {
        const first40Chars = itemName.substring(0, outputLen-30);
        const last10Chars = itemName.substring(itemName.length - 30);
        itemText = `${first40Chars}...${last10Chars}`;
    } else {
        itemText = itemName
    }

    return(itemText)
}

