import React, { useState, useEffect } from "react";
import { Table, Icon, Dropdown } from "semantic-ui-react";
import { updateValueInDatabase } from "../Component/FirebaseHelperFunctions";
import { ShortenItemName } from "../Component/AppGlobalFunctions";
import ShipmentViewShipment from "./Shipments_ViewShipment";


function Receipts_MissingTransaction({ setErrorMessage, receiptData, setReceiptData, allItems }) {

    const [displayReceipts, setDisplayReceipts] = useState([])
    const [openViewShipmentModal, setOpenViewShipmentModal] = useState(false);
    const [viewReceiptData, setViewReceiptData] = useState({});

    useEffect(() => {
        console.log(receiptData)
        // Sort the receipts by receiptDate in descending order
        const sortedReceipts = Object.keys(receiptData).sort((a, b) => new Date(receiptData[b].receipt.receiptDate) - new Date(receiptData[a].receipt.receiptDate));


        // display receipts where Shipment is to the East coast
        const ECShipments = sortedReceipts.filter(receiptID => receiptData[receiptID].receipt.deliveryMethod == "Ship to EC" && receiptData[receiptID].receipt.deliveryConfirmed !== true);

        setDisplayReceipts(ECShipments);

    }, [receiptData])
    const displayReceipt = (receiptID) => {
        setViewReceiptData({ receiptID: [receiptID], ...receiptData[receiptID] })
        setOpenViewShipmentModal(true)
    }

    const handleFlag = async (receiptID) => {
        try {
            const newFlag = !receiptData[receiptID].receipt.flag
            await updateValueInDatabase("Receipts/" + receiptID + "/receipt", { flag: newFlag })
            var updatedReceipt = receiptData[receiptID]
            updatedReceipt.receipt.flag = newFlag
            setReceiptData({ ...receiptData, [receiptID]: updatedReceipt })

        }
        catch (error) {
            console.log(error)
        }

    }

    const ReversedNotes = ({ notes }) => {
        if (notes) {
            let reversedNotesList = [...notes].reverse();

            return (
                <>
                    {notes && reversedNotesList.length > 0 ? (
                        <ul>
                            {reversedNotesList.map((note, index) => (
                                <li key={note}>{note}</li>
                            ))}
                        </ul>
                    ) : (
                        null
                    )}
                </>
            );
        } else {
            return null
        }

    };

    return (
        <>
        <p>{displayReceipts.length} Shipments on the way</p>
            <ShipmentViewShipment
                viewReceiptData={viewReceiptData}
                receiptData={receiptData}
                setReceiptData={setReceiptData}
                openViewShipmentModal={openViewShipmentModal}
                setOpenViewShipmentModal={setOpenViewShipmentModal}
                allItems={allItems}
            />

            <Table celled striped>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Receipt Date</Table.HeaderCell>
                        <Table.HeaderCell>Company</Table.HeaderCell>
                        <Table.HeaderCell>Items</Table.HeaderCell>
                        <Table.HeaderCell>Notes</Table.HeaderCell>   {/* Notes is a list */}
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell> {/* Notes is a Boolean */}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {displayReceipts.map((receiptID) => (

                        <Table.Row key={receiptID}>
                            <Table.Cell>
                                <a href="#" onClick={() => displayReceipt(receiptID)}>
                                    {receiptData[receiptID].receipt.receiptDate}
                                </a>
                            </Table.Cell>
                            <Table.Cell>{receiptData[receiptID].receipt.companyName}</Table.Cell>
                            <Table.Cell>
                                {receiptData[receiptID].items.map((item, index) => (
                                    <div key={index}>
                                        {ShortenItemName(item.itemName)}
                                    </div>
                                ))}</Table.Cell>
                            <Table.Cell style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <ReversedNotes notes={receiptData[receiptID].receipt.notes} />
                            </Table.Cell>
                            <Table.Cell>
                                {receiptData[receiptID].receipt.deliveryConfirmed ?
                                    <>
                                        {receiptData[receiptID].receipt.deliveryMethod}
                                        <Icon name="check" color="green" style={{ paddingLeft: "10px" }} />
                                    </>
                                    :
                                    <Dropdown
                                        text={receiptData[receiptID].receipt.deliveryMethod}
                                        options={
                                            [
                                                { key: receiptData[receiptID].receipt.deliveryMethod, 
                                                    text: receiptData[receiptID].receipt.deliveryMethod, 
                                                    value: receiptData[receiptID].receipt.deliveryMethod }, 
                                            { key: "Canceled", text: "Canceled", value: "Canceled" }]}
                                        onChange={(e, value) => { updateValueInDatabase("Receipts/" + receiptID + "/receipt", { deliveryMethod: value.value }) }}
                                    >
                                    </Dropdown>
                                }
                            </Table.Cell>
                            <Table.Cell>
                                {receiptData[receiptID].receipt.flag ?
                                    <a href="#" onClick={() => handleFlag(receiptID)}>
                                        <Icon name="flag" color="red" />
                                    </a> :
                                    <a href="#" onClick={() => handleFlag(receiptID)}>
                                        <Icon name="flag outline" color="grey" />
                                    </a>
                                }
                            </Table.Cell>
                        </Table.Row>
                    )
                    )}
                </Table.Body>

            </Table>

        </>
    )
}

export default Receipts_MissingTransaction