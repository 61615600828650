import React, { useState } from "react";
import NewReceipt from "../Container/NewReceipt";
import { Button, Grid, Menu } from "semantic-ui-react";
import TopNavBar from "../Component/TopNavBar";
import SideNavBar from "../Component/SideNavBar";


function GiftCards() {
//   const [openNewReceiptModal, setOpenNewReceiptModal] = useState(false);
  const [sectionSelected, setSectionSelected] = useState("All Gift Cards");

  return (
    <Grid>
      <Grid.Row>
        <TopNavBar />
      </Grid.Row>
      <Grid.Column width='2'>
        <SideNavBar pageName="Gift Cards " />
      </Grid.Column >
      <Grid.Column width='13'>

        <Menu attached='top' tabular>
          <Menu.Item
            name='All Gift Cards WIP'
            active={sectionSelected === "All Gift Cards"}
            onClick={() => setSectionSelected("All Gift Cards")}
          />

        </Menu>

        {/* {sectionSelected === "All Receipts" && <Receipts_AllReceipts />}
        {sectionSelected === "Missing Transactions" && <Receipts_MissingTransaction />}
        {sectionSelected === "Flagged" && <Receipts_Flagged />} */}

      </Grid.Column>
    </Grid>

  );
}

export default GiftCards;