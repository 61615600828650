import React, { useEffect, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import { ShortenItemName } from "../Component/AppGlobalFunctions";


function ItemProfitability({ receiptData, allOrders }) {

    const [itemsAgg, setItemsAgg] = useState({})
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null })

    useEffect(() => {

        let accumulator = {}
        let orderIDs = Object.keys(allOrders)
        // console.log("orderIDs: ",orderIDs)
        for (let orderID of orderIDs) {
            // console.log("orderID: ",orderID)
            const itemName = getItemName(orderID);
            if (!(itemName in accumulator)) {
                accumulator[itemName] = { "item-name": itemName, qty: 0, sourcingCost: 0, profit: 0, roi: 0, costs: 0 };
            }

            if (allOrders[orderID] && allOrders[orderID].Financials) {
                accumulator[itemName].qty += allOrders[orderID].Financials?.qty
                accumulator[itemName].sourcingCost += getSourcingCost(orderID)
                // accumulator[itemName].costs += getShipping(orderID)
                accumulator[itemName].costs += getTotalProfit(orderID)["totalCost"] * -1
                accumulator[itemName].profit += getTotalProfit(orderID)["totalProfit"]
                if (accumulator[itemName].costs + accumulator[itemName].sourcingCost <= 0) {
                    accumulator[itemName].roi = 0
                } else {
                    accumulator[itemName].roi = (accumulator[itemName].profit / (accumulator[itemName].sourcingCost + accumulator[itemName].costs) * 100)
                }
            }

        }

        console.log("accumulator: ", accumulator)

        setItemsAgg(accumulator)


    }, [allOrders])

    const getItemName = (orderID) => {
        let itemName = ""
        if (!allOrders[orderID] || !allOrders[orderID]["item-name"]) {
            return ""
        } else {
            itemName = ShortenItemName(allOrders[orderID]["item-name"], 70)

        }
        return itemName
    }
    const getSourcingCost = (orderID) => {
        let totalItemsCost = 0
        if (!allOrders[orderID] || !allOrders[orderID].receiptItemID) {
            return 0
        } else {
            allOrders[orderID].receiptItemID.map((itemID) => {

                let receiptID = itemID.slice(0, 20)
                let receiptItemID = itemID.slice(22).split("__")[0]
                totalItemsCost += receiptData[receiptID].items[receiptItemID].totalPrice / receiptData[receiptID].items[receiptItemID].qty

            })
        }
        return Number(totalItemsCost.toFixed(2))
    }


    const getTotalProfit = (orderID) => {

        if (!allOrders[orderID] || !allOrders[orderID].Financials) {
            return 0
        } else {
            let totalSourcingCost = getSourcingCost(orderID) * -1
            let totalProfit = totalSourcingCost
            let totalCost = totalSourcingCost

            for (let i = 0; i < Object.keys(allOrders[orderID].Financials).length - 1; i++) {
                if (Object.keys(allOrders[orderID].Financials)[i] !== "Tax" && Object.keys(allOrders[orderID].Financials)[i] !== "SellerSKU") {
                    totalProfit = totalProfit + Object.values(allOrders[orderID].Financials)[i]
                    if (Object.keys(allOrders[orderID].Financials)[i] !== "Principal" && Object.keys(allOrders[orderID].Financials)[i] !== "Refund_Principal") {
                        totalCost = totalCost + Object.values(allOrders[orderID].Financials)[i]
                    }
                }

            }

            return { totalProfit: Number(totalProfit.toFixed(2)), totalCost: Number(totalCost.toFixed(2)) }
        }

    }

    const onHeaderClick = (key) => {
        const direction =
            sortConfig.key === key && sortConfig.direction === 'ascending'
                ? 'descending'
                : 'ascending';
        setSortConfig({ key, direction });
    };

    const sortedItems = Object.keys(itemsAgg).sort((a, b) => {
        // Get the values of the items for the clicked key
        const itemAValue = itemsAgg[a][sortConfig.key];
        const itemBValue = itemsAgg[b][sortConfig.key];

        // Handle sorting if itemAValue or itemBValue is undefined
        if (itemAValue === undefined || itemBValue === undefined) {
            return 0;
        }

        // Perform numeric or string comparison based on the data type
        if (typeof itemAValue === 'number' && typeof itemBValue === 'number') {
            return sortConfig.direction === 'ascending'
                ? itemAValue - itemBValue
                : itemBValue - itemAValue;
        } else {
            // Convert both values to lowercase for case-insensitive sorting
            const itemALower = itemAValue.toLowerCase();
            const itemBLower = itemBValue.toLowerCase();

            if (sortConfig.direction === 'ascending') {
                return itemALower.localeCompare(itemBLower);
            } else {
                return itemBLower.localeCompare(itemALower);
            }
        }
    });

    return (
        <Grid style={{ marginTop: "10px" }}>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell >Item</Table.HeaderCell>
                        <Table.HeaderCell
                            style={{ cursor: 'pointer' }}
                            onClick={() => onHeaderClick('qty')}
                        >
                            Sales Qty
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            style={{ cursor: 'pointer' }}
                            onClick={() => onHeaderClick('sourcingCost')}
                        >
                            Sourcing Cost
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            style={{ cursor: 'pointer' }}
                            onClick={() => onHeaderClick('costs')}
                        >
                            Distribution Cost
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            style={{ cursor: 'pointer' }}
                            onClick={() => onHeaderClick('profit')}
                        >
                            Total Profit
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            style={{ cursor: 'pointer' }}
                            onClick={() => onHeaderClick('roi')}
                        >
                            ROI
                        </Table.HeaderCell>

                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {sortedItems.map((itemName) => (
                        <Table.Row key={itemName}>
                            <Table.Cell>{itemName}</Table.Cell>
                            <Table.Cell>{itemsAgg[itemName].qty.toFixed(0)}</Table.Cell>
                            <Table.Cell>{itemsAgg[itemName].sourcingCost.toFixed(0)}</Table.Cell>
                            <Table.Cell>{itemsAgg[itemName].costs.toFixed(0)}</Table.Cell>
                            <Table.Cell>{itemsAgg[itemName].profit.toFixed(0)}</Table.Cell>
                            <Table.Cell>{itemsAgg[itemName].roi.toFixed(0)}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Grid>

    )
}

export default ItemProfitability