import React, { useEffect, useState } from "react";
import { Container, Image } from "semantic-ui-react";
import { getURLFromBucket } from "../Component/FirebaseHelperFunctions";

function AttachementViewSection({ viewReceiptData, receiptData, setReceiptData }) {

    const [attachementsURL, setAttachementsURL] = useState([]);

    useEffect(() => {

        async function fetchData() {
            if (viewReceiptData.receipt.fileNames) {
                //itirate over every file name and implemetn getURLFromBucket
                for (let i = 0; i < viewReceiptData.receipt.fileNames.length; i++) {
                    let singleFile = {}
                    singleFile['name'] = viewReceiptData.receipt.fileNames[i]
                    singleFile['fileURL'] = await getURLFromBucket("Receipts/" + viewReceiptData.receipt.fileNames[i]);
                    let imageExtensions = ['jpg', 'png', 'jpeg', 'gif'];
                    singleFile['isImage'] = imageExtensions.includes(viewReceiptData.receipt.fileNames[i].split('.').pop().toLowerCase());
                    setAttachementsURL([...attachementsURL, singleFile])

                }
            }

        }

        fetchData()
    }, [])


    return (
        <Container fluid style={{ border: "1px solid rgba(0,0,0,.2)", borderRadius: "5px", marginTop: "10px" }}>
            <h3 style={{ padding: "10px" }}>Attachments</h3>
            {Object.keys(attachementsURL).length > 0 ? (
                Object.keys(attachementsURL).map((index) => (
                    <>
                        <li style={{ padding: "10px" }}>
                            <a key={index} href={attachementsURL[index].fileURL} target="_blank" rel="noopener noreferrer">
                                {attachementsURL[index].name}
                            </a>
                        </li>
                        {attachementsURL[index].isImage &&
                            <Image src={attachementsURL[index].fileURL} alt={attachementsURL[index].name} size='medium' />}
                    </>

                ))
            ) : (
                null
            )}
        </Container>
    );
}

export default AttachementViewSection