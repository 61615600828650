import React, { useEffect, useState } from "react";
import { Button, Grid, Divider, Header, Icon, Container } from "semantic-ui-react";
import { getAllfromDatabase, UploadToBucket, addValueToDatabase } from "../Component/FirebaseHelperFunctions";
import NewReceiptReceiptOverview from "../Component/Receipt_ReceiptInput";
import NewReceiptItemInputs from "../Component/Receipts_ItemInputs";
import { FormattedDate, FormattedDateTime } from "../Component/AppGlobalFunctions";
import TopNavBar from "../Component/TopNavBar";
import { useAuth } from '../Pages/AuthContext';
import{ useNavigate } from "react-router-dom";

const NewReceipt_forMobile = ({ }) => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    const { user } = useAuth();
    const navigate = useNavigate();

    const newItem = {
        itemName: "",
        qty: "",
        itemPrice: "",
        details: "",
        totalPrice: "",

    };

    const newReceipt = {
        companyName: "",
        receiptDate: formattedDate,
        cashAmount: "",
        giftCardAmount: "",
        totalAmount: "",
        itemsTotal: "",
        deliveryMethod: "",
        notes: [],
        fileList: [],
        status: "",
        flag: false,
        taxDeffered: false,
        transactions: [],
    }

    const [newReceiptData, setNewReceiptData] = useState({
        receipt: newReceipt,
        items: []
    });


    const [companyOptions, setCompanyOptions] = useState([]);
    const [deliveryMethodOptions, setDeliveryMethodOptions] = useState([]);
    const [itemOptions, setItemOptions] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const fetchdata = async () => {
        try {
            const tempCompanyOptions = await getAllfromDatabase("CompanyList")
            if (tempCompanyOptions) {
                setCompanyOptions(tempCompanyOptions);
            } else {
                setCompanyOptions({});
            }

            const tempDeliveryOptions = await getAllfromDatabase("Config/SupplyDeliveryMethod")
            if (tempDeliveryOptions) {
                setDeliveryMethodOptions(tempDeliveryOptions);
            } else {
                setDeliveryMethodOptions({});
            }

            var tempItemOptions = await getAllfromDatabase("ItemsReport");
            tempItemOptions[0] = { "item-name": "--Other Business Expense" }
            tempItemOptions[1] = { "item-name": "--Shipping Expense (ex. PirateShip)" }
            tempItemOptions[2] = { "item-name": "--Packing Expense (ex. Boxes)" } //Adding Other Business expense to the items option list to allow for manual input of non COGS expenses
            tempItemOptions[4] = { "item-name": "--Items will Not Be Listed" }
            setItemOptions(tempItemOptions);

        } catch (error) {
            setErrorMessage("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (!user) {
            // User is not authenticated, navigate to the login page
            console.log("no user");
            navigate('/login');
            return;
          }else{
            setErrorMessage("");
            fetchdata();
          }

    }, []);

    const handleAddItem = () => {
        setNewReceiptData({ ...newReceiptData, items: [...newReceiptData.items, newItem] });
    };

    const handleDeleteItem = (index) => {
        const updatedItems = [...newReceiptData.items];
        updatedItems.splice(index, 1);
        setNewReceiptData({ ...newReceiptData, items: updatedItems });
    };

    const handleSubmit = async () => {
        const updatedReceipt = newReceiptData.receipt
        setErrorMessage("")
        let submitError = ""

        // Check if all required fields are filled
        if (newReceiptData.items.length == 0) { submitError = "Please enter item details" }
        if (newReceiptData.receipt.fileList.length == 0) { submitError = "Please upload receipt image or PDF" }
        if (!newReceiptData.receipt.deliveryMethod) { submitError = "Please select delivery method" }
        if (!newReceiptData.receipt.totalAmount) { submitError = "Please enter Receipt Cash/Gift card amount" }
        if (!newReceiptData.receipt.receiptDate) { submitError = "Please select date" }
        if (!newReceiptData.receipt.companyName) { submitError = "Please select company" }

        if (!submitError) {

            // Add date to the name of every file uploaded
            if (updatedReceipt.fileNames) {
                updatedReceipt.fileNames = updatedReceipt.fileNames.map(fileName => FormattedDate() + "_" + fileName);
            }
            if (updatedReceipt.notes.length > 0) {
                updatedReceipt.notes[0] = FormattedDateTime() + "-" + newReceiptData.receipt.notes[0];
            }
            const finalReceiptData = { ...newReceiptData, receipt: updatedReceipt }

            try {
                await UploadToBucket("Receipts/", finalReceiptData.receipt.fileList, finalReceiptData.receipt.fileNames)
            } catch (error) {
                setErrorMessage("Error uploading file to bucket:", error)
            }

            try {
                await addValueToDatabase("Receipts/", finalReceiptData);
            } catch (error) {
                setErrorMessage("Error updating database:", error)
            }

            setNewReceiptData({
                receipt: newReceipt,
                items: []
            })
        } else {
            setErrorMessage(submitError)
        }
    }





    return (
        <>
        <TopNavBar/>
        <Container>
        <Grid divided='vertically' style={{ marginLeft: "15px", marginRight: "15px" }}>

            <Header as='h3' style={{ marginTop: "15px" }} color="teal">
                <Icon name='file text'
                    color="teal" />
                <Header.Content>Create New Receipt</Header.Content>
            </Header>

            

            <Grid.Row>
                <NewReceiptReceiptOverview
                    companyOptions={companyOptions}
                    deliveryMethodOptions={deliveryMethodOptions}
                    newReceiptData={newReceiptData}
                    setNewReceiptData={setNewReceiptData}
                />
            </Grid.Row>

            <Divider horizontal>Items Total: {newReceiptData.receipt.itemsTotal}</Divider>

            <Grid.Row>

                {newReceiptData.items.map((item, index) => (
                    <NewReceiptItemInputs
                        key={index}
                        newReceiptData={newReceiptData}
                        index={index}
                        setNewReceiptData={setNewReceiptData}
                        itemNameOptions={itemOptions}
                        handleDeleteItem={() => handleDeleteItem(index)
                        }
                    />
                ))}

            </Grid.Row>
            
                <Button icon="plus" content="Add Item" color="teal" onClick={handleAddItem} style={{ marginTop: "10px", marginBottom: "10px" }} size="tiny" />

            

            <Grid.Row>
                <p style={{ color: "red" }}>{errorMessage}</p>
                <Button
                    content="Create Receipt"
                    labelPosition='right'
                    icon='checkmark'
                    color="teal"
                    onClick={handleSubmit}
                />
            </Grid.Row>
        </Grid>
        </Container>
        </>
    );
}

export default NewReceipt_forMobile;
