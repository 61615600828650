import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getStorage, } from "firebase/storage"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBF1b12lz1hjsZ0Z8u_axXg4uMFKD4d-9w",
  authDomain: "manualautomate-b12fd.firebaseapp.com",
  databaseURL: "https://manualautomate-b12fd-default-rtdb.firebaseio.com",
  projectId: "manualautomate-b12fd",
  storageBucket: "manualautomate-b12fd.appspot.com",
  messagingSenderId: "190401228483",
  appId: "1:190401228483:web:c4b0855f68866a28417c76"
};

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);
const auth = getAuth(firebaseApp);
const myfunctions = getFunctions(firebaseApp);
const storage = getStorage(firebaseApp);

export { database, auth, myfunctions, storage };