import React from "react";
import { Modal, Header, Icon, Button, Table, Container } from "semantic-ui-react";
import { updateValueInDatabase } from "../Component/FirebaseHelperFunctions";

function OrderDetails({ allOrders, orderID, orderDetailsModalOpen, setOrderDetailsModalOpen }) {

    return (

        <Modal
            size="large"
            closeIcon
            open={orderDetailsModalOpen}
            onClose={() => setOrderDetailsModalOpen(false)}
            onOpen={() => setOrderDetailsModalOpen(true)}
        >
            <Header > View Order </Header>

            <Modal.Content>

                <Table celled striped>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Order ID</Table.HeaderCell>
                            <Table.HeaderCell>Order Date</Table.HeaderCell>
                            <Table.HeaderCell>Qty</Table.HeaderCell>
                            <Table.HeaderCell>Fulfillment type</Table.HeaderCell>   {/* Notes is a list */}
                            <Table.HeaderCell>Item Name</Table.HeaderCell>
                            <Table.HeaderCell>Receipt __ Item ID</Table.HeaderCell> {/* Notes is a Boolean */}
                        </Table.Row>
                    </Table.Header>


                    <Table.Body>
                        <Table.Row key={orderID}>
                            <Table.Cell>{[orderID]}</Table.Cell>
                            <Table.Cell>{allOrders[orderID]?.PurchaseDate}</Table.Cell>
                            <Table.Cell>{allOrders[orderID]?.Financials?.qty}</Table.Cell>
                            <Table.Cell>{allOrders[orderID]?.FulfillmentChannel}</Table.Cell>
                            <Table.Cell>{allOrders[orderID]?.["item-name"]}</Table.Cell>
                            <Table.Cell>{allOrders[orderID]?.["receiptItemID"]}</Table.Cell>

                        </Table.Row>

                    </Table.Body>
                </Table>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Line Item Name</Table.HeaderCell>
                            <Table.HeaderCell>Line Item Amount</Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    {allOrders[orderID]?.Financials && 
                    
                    Object.keys(allOrders[orderID].Financials).map((key) => {
                        if (key !== "SellerSKU" && key !== "qty" && key !== "Tax") {
                            return (
                                <Table.Row key={key}>
                                    <Table.Cell>{key}</Table.Cell>
                                    <Table.Cell>{allOrders[orderID].Financials[key]}</Table.Cell>
                                </Table.Row>
                            );
                        }
                    })}
                </Table>




            </Modal.Content>

            <Modal.Actions>
                <Button color='teal' onClick={() => setOrderDetailsModalOpen(false)}>
                    <Icon name='remove' /> Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default OrderDetails