import React, { useEffect, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import { ShortenItemName } from "../Component/AppGlobalFunctions";
import OrderDetails from "../Component/Profitability_OrderDetails";


function OrderProfitability({ receiptData, allOrders }) {

    const [sortedOrders, setSortedOrders] = useState([])
    const [orderDetailsModalOpen, setOrderDetailsModalOpen] = useState(false)
    const [orderDetailsOrderID, setOrderDetailsOrderID] = useState(null)

    useEffect(() => {

        // let first5OrderKeys = Object.keys(allOrders).slice(0, 5)

        setSortedOrders(
            Object.keys(allOrders).sort((a, b) => {
                return new Date(allOrders[b]["PurchaseDate"]) - new Date(allOrders[a]["PurchaseDate"])
            })
        );


    }, [allOrders])

    const getItemName = (orderID) => {
        let itemName = ""
        if (!allOrders[orderID] || !allOrders[orderID]["item-name"]) {
            return ""
        } else {
            itemName = ShortenItemName(allOrders[orderID]["item-name"])

        }
        return itemName
    }
    const getSourcingCost = (orderID) => {
        let totalItemsCost = 0
        if (!allOrders[orderID] || !allOrders[orderID].receiptItemID) {
            return 0
        } else {
            allOrders[orderID].receiptItemID.map((itemID) => {

                let receiptID = itemID.slice(0, 20)
                let receiptItemID = itemID.slice(22).split("__")[0]
                totalItemsCost += receiptData[receiptID].items[receiptItemID].totalPrice / receiptData[receiptID].items[receiptItemID].qty

            })
        }
        return Number(totalItemsCost.toFixed(2))
    }

    const getShipping = (orderID) => {
        let totalShipping = 0
        if (!allOrders[orderID] || !allOrders[orderID].Financials) {
            return 0
        } else {
            totalShipping = (-allOrders[orderID]?.Financials.ShippingCharge || 0) +
                (-allOrders[orderID]?.Financials.Adjustment_PostageBilling_Postage || 0) +
                (-allOrders[orderID]?.Financials.Adjustment_PostageBilling_Insurance || 0) +
                (allOrders[orderID]?.Financials.ShippingCharge || 0) +
                (-allOrders[orderID]?.Financials.Adjustment_PostageBilling_SignatureConfirmation || 0);
        }
        return Number(totalShipping.toFixed(2))
    }

    const getFees = (orderID) => {
        let totalFees = 0
        if (!allOrders[orderID] || !allOrders[orderID].Financials) {
            return 0
        } else {
            totalFees = (-allOrders[orderID]?.Financials.Commission || 0) +
                (-allOrders[orderID]?.Financials.FBAPerUnitFulfillmentFee || 0);
        }
        return Number(totalFees.toFixed(2))
    }

    const getReturnCost = (orderID) => {
        let totalReturnCost = 0
        if (!allOrders[orderID] || !allOrders[orderID].Financials) {
            return 0
        } else {
            totalReturnCost = (-allOrders[orderID]?.Financials.Adjustment_ReturnPostageBilling_FuelSurcharge || 0) +
                (-allOrders[orderID]?.Financials.Adjustment_ReturnPostageBilling_Postage || 0) +
                (-allOrders[orderID]?.Financials.Refund_Principal || 0)
        }
        return Number(totalReturnCost.toFixed(2))
    }

    const getTotalProfit = (orderID) => {

        if (!allOrders[orderID] || !allOrders[orderID].Financials) {
            return 0
        } else {
            let totalSourcingCost = getSourcingCost(orderID)*-1
            let totalProfit = totalSourcingCost
            let totalCost = totalSourcingCost

           for (let i = 0; i < Object.keys(allOrders[orderID].Financials).length; i++) {   
                if (Object.keys(allOrders[orderID].Financials)[i] !== "Tax" && Object.keys(allOrders[orderID].Financials)[i] !== "SellerSKU") {
                    totalProfit = totalProfit + Object.values(allOrders[orderID].Financials)[i]
                    if (Object.keys(allOrders[orderID].Financials)[i] !== "Principal" && Object.keys(allOrders[orderID].Financials)[i] !== "Refund_Principal") {
                        totalCost = totalCost + Object.values(allOrders[orderID].Financials)[i]
                    }
                }
                 
            }
            
            return {totalProfit: Number(totalProfit.toFixed(2)), totalCost: Number(totalCost.toFixed(2))}
        }

    }

    const getROI = (orderID) => {
        let totalProfit = getTotalProfit(orderID)
        let roi = 0
        if (!allOrders[orderID] || !allOrders[orderID].Financials || totalProfit["totalCost"] == 0 || !allOrders[orderID] === "Canceled") {
            return 0
        } else {
            roi = totalProfit["totalProfit"] / -totalProfit["totalCost"] * 100
        }
        return(Number(roi.toFixed(0)))
    }


    const handleShowOrderDetails = (orderID) => {
        setOrderDetailsOrderID(orderID)
        setOrderDetailsModalOpen(true)
    }
    return (
        <Grid style={{ marginTop: "10px" }}>
            <OrderDetails allOrders={allOrders} orderID={orderDetailsOrderID} orderDetailsModalOpen={orderDetailsModalOpen} setOrderDetailsModalOpen={setOrderDetailsModalOpen} />
            <Table celled>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>OrderDate</Table.HeaderCell>
                        <Table.HeaderCell>Item</Table.HeaderCell>
                        <Table.HeaderCell>Count</Table.HeaderCell>
                        <Table.HeaderCell>Sourcing Cost</Table.HeaderCell>
                        <Table.HeaderCell>Price</Table.HeaderCell>
                        <Table.HeaderCell>Shipping</Table.HeaderCell>
                        <Table.HeaderCell>Fees</Table.HeaderCell>
                        <Table.HeaderCell>Return Cost</Table.HeaderCell>
                        <Table.HeaderCell>Total Profit</Table.HeaderCell>
                        <Table.HeaderCell>ROI</Table.HeaderCell>

                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {sortedOrders.map((orderID) => {
                        if (allOrders[orderID].OrderStatus !== "Canceled") {
                            return (
                                <Table.Row key={orderID}>
                                    <Table.Cell style={{ cursor: 'pointer' }}  onClick={() => handleShowOrderDetails(orderID)}>
                                        <a>{allOrders[orderID].PurchaseDate &&
                                            new Date(allOrders[orderID].PurchaseDate).toISOString().slice(0, 10)}</a>
                                    </Table.Cell>
                                    <Table.Cell> {getItemName(orderID)}</Table.Cell>
                                    <Table.Cell> {allOrders[orderID]?.Financials?.qty}</Table.Cell>
                                    <Table.Cell> {getSourcingCost(orderID)}</Table.Cell>
                                    <Table.Cell> {allOrders[orderID]?.Financials?.Principal}</Table.Cell>
                                    <Table.Cell> {getShipping(orderID)}</Table.Cell>
                                    <Table.Cell> {getFees(orderID)}</Table.Cell>
                                    <Table.Cell> {getReturnCost(orderID)}</Table.Cell>
                                    <Table.Cell> {getTotalProfit(orderID).totalProfit}</Table.Cell> 
                                    <Table.Cell> {getROI(orderID)}</Table.Cell>
    
    
                                </Table.Row>
                            )
                        }
                    }

                    )}
                </Table.Body>

            </Table>
        </Grid>

    )
}

export default OrderProfitability