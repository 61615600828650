import React, { useState } from "react";
import { Button, Grid, Menu } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";


function SideNavBar({ pageName }) {
  const navigate = useNavigate();

  return (
    <Menu fluid vertical tabular>
      <Menu.Item
        name='Receipts'
        active={pageName === 'Receipts'}
        onClick={() => navigate('/Receipts')}
      />
      <Menu.Item
        name='Shipments'
        active={pageName === 'Shipments'}
        onClick={() => navigate('/Shipments')}
      />
      <Menu.Item
        name='Transactions'
        active={pageName === 'Transactions'}
        onClick={() => navigate('/Transactions')}
      />

      <Menu.Item
        name={'WIP Gift Cards '}
        active={pageName === 'Gift Cards'}
        onClick={() => navigate('/GiftCards')}
      />

      <Menu.Item
        name='Profitability'
        active={pageName === 'Profitability'}
        onClick={() => navigate('/Profitability')}
      />

      {/* <Menu.Item
        name='Amazon Listings'
        active={pageName === 'Amazon Listings'}
        onClick={() => navigate('/AmazonListings')}
      />

      <Menu.Item
        name='Amazon Orders'
        active={pageName === 'Amazon Orders'}
        onClick={() => navigate('/AmazonOrders')}
      /> */}
    </Menu>

  )
}
export default SideNavBar