import React, { useState, useEffect } from "react";
import { Table, Modal, Checkbox, Header, Button, Icon, Divider } from "semantic-ui-react";
import {updateValueInDatabase} from "../Component/FirebaseHelperFunctions";


function ReceiptMatching({
    allReceipts,
    setAllReceipts,
    transactionForReciptSearch,
    transactionsData,
    setTransactionsData,
    openReceiptMatchingModal,
    setOpenReceiptMatchingModal }) {

    const [mostLikelyReceipts, setMostLikelyReceipts] = useState([]);

    useEffect(() => {
        const dayMatch = []
        const dayAndAmountMatch = []

        const filterMostLikelyReceipts = Object.keys(allReceipts).filter(receiptID => {
            // Check if receipt date is within 2 days of the transaction date
            const isWithinTwoDays = Math.abs(new Date(allReceipts[receiptID].receipt.receiptDate) - new Date(transactionForReciptSearch.Date)) <= 2 * 24 * 60 * 60 * 1000;

            // Check if receipt amount is within $2 from the transaction amount
            const isWithinTwoDollars = Math.abs(allReceipts[receiptID].receipt.totalAmount - transactionForReciptSearch.Amount) <= 2;

            if (isWithinTwoDays && isWithinTwoDollars) {
                dayAndAmountMatch.push(receiptID)
            } else if (isWithinTwoDays) {
                dayMatch.push(receiptID)
            }
        })

        if (dayAndAmountMatch.length > 0) {
            setMostLikelyReceipts(dayAndAmountMatch)
        } else {
            setMostLikelyReceipts(dayMatch)
        }

    }, [transactionForReciptSearch])

    return (
        <Modal
            open={openReceiptMatchingModal}
            onClose={() => setOpenReceiptMatchingModal(false)}
            onOpen={() => setOpenReceiptMatchingModal(true)}
        >
            <Header> Find The Receipt</Header>

            <Table striped>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Transaction Card</Table.HeaderCell>
                        <Table.HeaderCell>Transaction Date</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>


                <Table.Body>
                    {transactionForReciptSearch &&

                        <Table.Row key={transactionForReciptSearch.transactionID}>
                            <Table.Cell>{transactionForReciptSearch.Card}</Table.Cell>
                            <Table.Cell>{transactionForReciptSearch.Date}</Table.Cell>
                            <Table.Cell>{transactionForReciptSearch.Description}</Table.Cell>
                            <Table.Cell>{transactionForReciptSearch.Amount}</Table.Cell>

                            <Table.Cell>
                                {transactionForReciptSearch.receipts?.length > 0 ?
                                    <Icon
                                        name='file text'
                                        color="green" /> :
                                    null}
                                {transactionForReciptSearch.receipts && transactionForReciptSearch.receipts[0] ==="Override" ?
                                    <Icon
                                        name='tag'
                                        color="yellow" /> :
                                    null}
                                

                            </Table.Cell>


                        </Table.Row>

                    }
                </Table.Body>

            </Table>
            {/* ------------------------------------------------------------------------------------------------------------------------------ */}
            <Divider horizontal>Most Likely Matches</Divider>
            {/* ------------------------------------------------------------------------------------------------------------------------------ */}
            <Table celled>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Receipt Date</Table.HeaderCell>
                        <Table.HeaderCell>Issuer Name</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Receipt Files</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>



                {allReceipts &&
                    <Table.Body>

                        {mostLikelyReceipts.map((receiptID) => (

                            <Table.Row key={receiptID}>
                                <Table.Cell>{allReceipts[receiptID].receipt.receiptDate}</Table.Cell>
                                <Table.Cell>{allReceipts[receiptID].receipt.companyName}</Table.Cell>
                                <Table.Cell>{allReceipts[receiptID].receipt.cashAmount}</Table.Cell>
                                <Table.Cell>Map Files Later</Table.Cell>
                                <Table.Cell>
                                    <Checkbox
                                        checked={
                                            allReceipts[receiptID]?.receipt?.transactions?.includes(transactionForReciptSearch.transactionID) || false
                                          }
                                        onChange={(e, { checked }) => {
                                            const updatedReceipt = allReceipts[receiptID]

                                            if (!updatedReceipt.receipt.transactions)
                                            updatedReceipt.receipt.transactions = [];

                                            if (!transactionForReciptSearch.receipts)
                                            transactionForReciptSearch.receipts = [];

                                            console.log(transactionForReciptSearch)
                                            if (checked) {
                                                // Add transactionID to transactions list
                                                updatedReceipt.receipt.transactions.push(transactionForReciptSearch.transactionID);
                                                transactionForReciptSearch.receipts.push(receiptID)
                                                console.log("transactionForReciptSearch After push",transactionForReciptSearch)
                                              } else {
                                                // Remove transactionID from transactions list
                                                updatedReceipt.receipt.transactions = updatedReceipt.receipt.transactions.filter(
                                                  id => id !== transactionForReciptSearch.transactionID
                                                );
                                                transactionForReciptSearch.receipts = transactionForReciptSearch.receipts.filter(
                                                  id => id !== receiptID
                                                )
                                              }

                                              updateValueInDatabase(`Receipts/${receiptID}/receipt`, { transactions: updatedReceipt.receipt.transactions})
                                              setTransactionsData({ ...transactionsData, [transactionForReciptSearch.transactionID]: transactionForReciptSearch })
                                              updateValueInDatabase(`Transactions/${transactionForReciptSearch.transactionID}`, { receipts: transactionForReciptSearch.receipts})
                                            setAllReceipts({ ...allReceipts, [receiptID]: updatedReceipt });
                                        }}
                                    />

                                </Table.Cell>

                            </Table.Row>

                        ))}
                    </Table.Body>
                }

            </Table>
            {/* --------------------------------------------------------------------------------------------------------------------------------- */}
            <Divider horizontal>All Receipts</Divider>
            {/* ------------------------------------------------------------------------------------------------------------------------------ */}
            <Table celled>

                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Receipt Date</Table.HeaderCell>
                        <Table.HeaderCell>Issuer Name</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                {allReceipts &&
                    <Table.Body>

                        {Object.keys(allReceipts).map((receiptID) => (

                            <Table.Row key={receiptID}>
                                <Table.Cell>{allReceipts[receiptID].receipt.receiptDate}</Table.Cell>
                                <Table.Cell>{allReceipts[receiptID].receipt.companyName}</Table.Cell>
                                <Table.Cell>{allReceipts[receiptID].receipt.cashAmount}</Table.Cell>
                                <Table.Cell>
                                    <Checkbox
                                        checked={
                                            allReceipts[receiptID]?.receipt?.transactions?.includes(transactionForReciptSearch.transactionID) || false
                                          }
                                        onChange={(e, { checked }) => {
                                            const updatedReceipt = allReceipts[receiptID]

                                            if (!updatedReceipt.receipt.transactions)
                                            updatedReceipt.receipt.transactions = [];

                                            if (!transactionForReciptSearch.receipts)
                                            transactionForReciptSearch.receipts = [];

                                            if (checked) {
                                                // Add transactionID to transactions list
                                                updatedReceipt.receipt.transactions.push(transactionForReciptSearch.transactionID);
                                                transactionForReciptSearch.receipts.push(receiptID)
                                              } else {
                                                // Remove transactionID from transactions list
                                                updatedReceipt.receipt.transactions = updatedReceipt.receipt.transactions.filter(
                                                  id => id !== transactionForReciptSearch.transactionID
                                                );
                                                transactionForReciptSearch.receipts = transactionForReciptSearch.receipts.filter(
                                                  id => id !== receiptID
                                                )
                                              }

                                              updateValueInDatabase(`Receipts/${receiptID}/receipt`, { transactions: updatedReceipt.receipt.transactions})
                                              setTransactionsData({ ...transactionsData, [transactionForReciptSearch.transactionID]: transactionForReciptSearch })
                                              updateValueInDatabase(`Transactions/${transactionForReciptSearch.transactionID}`, { receipts: transactionForReciptSearch.receipts})
                                            setAllReceipts({ ...allReceipts, [receiptID]: updatedReceipt });
                                        }}
                                    />

                                </Table.Cell>

                            </Table.Row>

                        ))}
                    </Table.Body>
                }
            </Table>


            <Modal.Actions>
                <Button color='grey' onClick={() => setOpenReceiptMatchingModal(false)}>
                    <Icon name='remove' /> Close
                </Button>
            </Modal.Actions>

        </Modal>

    )
}
export default ReceiptMatching