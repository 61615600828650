import React, { useState, useEffect } from "react";
import { Container, Button, Input } from "semantic-ui-react"
import { updateValueInDatabase } from "../Component/FirebaseHelperFunctions";
import { FormattedDateTime } from "../Component/AppGlobalFunctions";

function NotesSection({ viewReceiptData, receiptData, setReceiptData }) {
    const [newPost, setNewPost] = useState("");
    
    let reversedNotes =[]
    const ReversedNotes = () => {
        if(viewReceiptData.receipt.notes){
            reversedNotes = [...viewReceiptData.receipt.notes].reverse()
            console.log("ReversedNotes", reversedNotes)

            return (
                <ul>
                    {reversedNotes.map((item, index) => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>)
        }else{
            return null
        }




    }
    return (
        <Container fluid style={{ border: "1px solid rgba(0,0,0,.2)", borderRadius: "5px" }} >

            <h3 style={{ padding: "10px" }}>Notes</h3>
            <Input
                style={{ padding: "10px" }}
                fluid
                onChange={(e, { value }) => setNewPost(value)}
                placeholder='Notes'
                action={{
                    color: 'teal',
                    content: 'Post Note',

                    onClick: () => {
                        if (newPost) {
                            console.log("NewPost", newPost)
                            let datednewPost = FormattedDateTime() + "-" + newPost;
                            console.log(viewReceiptData.receipt)
                            let updatedPosts =[]
                            if(viewReceiptData.receipt.notes){
                                updatedPosts = [...viewReceiptData.receipt.notes, datednewPost]
                            }else{
                                updatedPosts = [datednewPost]    
                            }
                            updateValueInDatabase("Receipts/" + viewReceiptData.receiptID + "/receipt", { notes: updatedPosts })
                            let updatedReceipt = { ...viewReceiptData }
                            updatedReceipt.receipt.notes = updatedPosts
                            console.log("UpdatedReceiptData", { ...receiptData, [viewReceiptData.receiptID]: { receipt: updatedReceipt.receipt, items: updatedReceipt.items } })
                            setReceiptData({ ...receiptData, [viewReceiptData.receiptID]: { receipt: updatedReceipt.receipt, items: updatedReceipt.items } })
                            setNewPost("")

                        }
                    }
                }}
            />

            {reversedNotes ?
                <ReversedNotes/> :
                <p>No Notes</p>}


        </Container>
    );
}

export default NotesSection