import React from "react";
import { Grid, Input, Checkbox } from "semantic-ui-react";
import SearchableDropdown from "./SearchableDropdown";


const NewReceiptReceiptOverview = ({ setNewReceiptData, newReceiptData, deliveryMethodOptions, companyOptions, setCompanyOptions }) => {

    const handleDeliveryMethodSelection = (value) => {
        const updatedReceipt = newReceiptData.receipt
        updatedReceipt.deliveryMethod = value;
        setNewReceiptData({ ...newReceiptData, receipt: updatedReceipt });
    }

    const handleCompanySelection = (value) => {
        console.log(value)
        const updatedReceipt = newReceiptData.receipt
        updatedReceipt.companyName = value;
        setNewReceiptData({ ...newReceiptData, receipt: updatedReceipt });
    }

    const handleFileChange = (selectedFiles) => {
        const updatedReceipt = newReceiptData.receipt
        updatedReceipt.fileList = selectedFiles;
        const fileNames = Object.keys(selectedFiles).map(key => selectedFiles[key].name)
        updatedReceipt.fileNames = [...fileNames];
        setNewReceiptData({ ...newReceiptData, receipt: updatedReceipt });
    }


    return (
        <Grid stackable>
            <Grid.Row stackable>
                <Grid.Column width='3' stackable>
                    <SearchableDropdown
                        searchable={true}
                        addItem={true}
                        addValueDBPath="CompanyList"
                        icon='factory'
                        dropdownLabel='Company Name'
                        inputOptions={companyOptions}
                        setInputOptions={setCompanyOptions}
                        handleSelection={handleCompanySelection}
                        inputSubselectionLabel="Name"
                        sortalphabetic={true}
                    />

                </Grid.Column>

                <Grid.Column width='2' stackable>
                    <Input
                        label="Date"
                        type="date"
                        value={newReceiptData.receipt.receiptDate}
                        onChange={(e, { value }) => {
                            const updatedReceipt = newReceiptData.receipt
                            updatedReceipt.receiptDate = value;
                            setNewReceiptData({ ...newReceiptData, receipt: updatedReceipt });
                        }}
                        onClick={(e) => e.stopPropagation()}
                        size='small'
                        max={new Date().toISOString().split("T")[0]}
                        
                    />
                </Grid.Column>

                <Grid.Column width='3' stackable>

                    <SearchableDropdown
                        icon='truck'
                        dropdownLabel='Delivery Method'
                        inputOptions={deliveryMethodOptions}
                        handleSelection={handleDeliveryMethodSelection}
                        sortalphabetic={true}
                    />
                </Grid.Column>
                <Grid.Column width='3' stackable>
                    <Input
                        label='Notes'
                        value={newReceiptData.receipt.notes}
                        onChange={(e, { value }) => {
                            const updatedReceipt = newReceiptData.receipt
                            updatedReceipt.notes = [value,];
                            setNewReceiptData({ ...newReceiptData, receipt: updatedReceipt });
                        }}
                        size='small'
                        fluid
                    />
                </Grid.Column>

                <Grid.Column width='4' stackable>

                    <Input
                        type="file"
                        className="form-control"
                        onChange={(e) => handleFileChange(e.target.files)}
                        size="small"
                        multiple
                        fluid
                    ></Input>
                </Grid.Column>

            </Grid.Row>

            <Grid.Row>

                    <Grid.Column width='3' textAlign='right' stackable>
                        <Input
                            type='number'
                            label='Cash'
                            placeholder="$0.00"
                            value={newReceiptData.receipt.cashAmount}
                            onChange={(e, { value }) => {
                                const updatedReceipt = newReceiptData.receipt
                                updatedReceipt.cashAmount = value;
                                if (!updatedReceipt.totalAmount) { updatedReceipt.totalAmount = 0 }
                                if (!updatedReceipt.giftCardAmount) { updatedReceipt.giftCardAmount = 0 }
                                updatedReceipt.totalAmount = parseFloat(updatedReceipt.giftCardAmount)  +parseFloat(value);
                                setNewReceiptData({ ...newReceiptData, receipt: updatedReceipt });

                            }}
                            onClick={(e) => e.stopPropagation()}
                            size='small'
                            fluid
                        />
                    </Grid.Column>
                    <Grid.Column verticalAlign='middle' textAlign="center" stackable>
                        <p style={{ "font-size": "20px", "font-weight": "bold" }}>+</p>
                    </Grid.Column>


                    <Grid.Column width='3' stackable>
                        <Input
                            type='number'
                            label='Gift Card'
                            placeholder="0.00"
                            value={newReceiptData.receipt.giftCardAmount}
                            onChange={(e, { value }) => {
                                const updatedReceipt = newReceiptData.receipt
                                if (!updatedReceipt.totalAmount) { updatedReceipt.totalAmount = 0 }
                                if (!updatedReceipt.totalAmount) { updatedReceipt.cashAmount = 0 }
                                updatedReceipt.giftCardAmount = value;
                                updatedReceipt.totalAmount = parseFloat(newReceiptData.receipt.cashAmount)  + parseFloat(value);
                                setNewReceiptData({ ...newReceiptData, receipt: updatedReceipt });
                            }}
                            onClick={(e) => e.stopPropagation()}
                            size='small'
                            fluid
                        />
                    </Grid.Column>

                    <Grid.Column verticalAlign='middle' stackable>
                        <p style={{ "font-size": "20px", "font-weight": "bold" }}>=</p>
                    </Grid.Column>
                    <Grid.Column width='3' stackable>
                        <Input
                            type='number'
                            label='Total'
                            placeholder="0.00"
                            value={newReceiptData.receipt.totalAmount}
                            onChange={(e, { value }) => {
                                const updatedReceipt = newReceiptData.receipt
                                updatedReceipt.totalAmount = value;
                                setNewReceiptData({ ...newReceiptData, receipt: updatedReceipt });
                            }}
                            onClick={(e) => e.stopPropagation()}
                            size='small'
                            fluid
                        />
                    </Grid.Column>

                    <Grid.Column width='2' verticalAlign='middle' stackable>
                    <Checkbox
                        label='Tax Deffered'
                        checked={newReceiptData.receipt.taxDeffered}
                        onChange={(e, { checked }) => {
                            const updatedReceipt = newReceiptData.receipt
                            updatedReceipt.taxDeffered = checked;
                            setNewReceiptData({ ...newReceiptData, receipt: updatedReceipt });
                        }}
                    />
                </Grid.Column>


            </Grid.Row>


        </Grid>
    );
}

export default NewReceiptReceiptOverview;
