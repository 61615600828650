import React, { useState } from "react";
import { storage, auth, database, myfunctions } from "../firebase";
import { ref as storageRef, uploadBytes, getDownloadURL  } from "firebase/storage";
import { ref as dbRef, onValue, push, set, update } from "firebase/database";


export const UploadToBucket = async (path, files, names) => {

        Object.keys(files).forEach((index) => {
            const fileName =  names[index]
            const storageRefResponse = storageRef(storage, path + fileName);
            uploadBytes(storageRefResponse, files[index]).then((snapshot) => {
                console.log('Uploaded file:', fileName);
            });
        });
};

export const getURLFromBucket = async (path) => {
    const fileRef = storageRef(storage, path)
    const downloadURL = await getDownloadURL(fileRef);
    return downloadURL
}

export const getAllfromDatabase = async (path) => {
    return new Promise((resolve, reject) => {
        const dbRefResponse = dbRef(database, path);
        onValue(dbRefResponse, (snapshot) => {
            const data = snapshot.val();
            resolve(data);
        }, (error) => {
            console.error("Error fetching data from database:", error);
            reject(error);
        });
    });
};

export const addValueToDatabase = async (path, dataDictionary) => {
    return new Promise(async (resolve, reject) => {

        const pathRef = dbRef(database, path);

        try {
            const newPostRef = push(pathRef);
            // Use set to directly set the data under the specified key
            await set(newPostRef, dataDictionary);

            // Return the key if needed
            resolve(newPostRef.key);
        } catch (error) {
            // Handle the error here
            console.error("Error writing data to the database:", error);
            reject(error); // Propagate the error if needed
        }
    });
};

export const updateValueInDatabase = async (path, updatedValue) => {
    return new Promise((resolve, reject) => {
        const dbRefToUpdate = dbRef(database, path);
        update(dbRefToUpdate, updatedValue)
            .then(() => {
                console.log("Value updated successfully");
                resolve("Value updated successfully");
            })
            .catch((error) => {
                console.error("Error updating value in database:", error);
                reject(error);
            });
    });
};