import React from "react";
import { Modal, Header, Icon, Button, Table, Input } from "semantic-ui-react";
import { updateValueInDatabase } from "../Component/FirebaseHelperFunctions";
import NotesSection from "../Component/NotesSection"
import AttachementViewSection from "../Component/AttachementViewSection"

function ViewReceipt({ viewReceiptData, receiptData, setReceiptData, openViewReceiptModal, setOpenViewReceiptModal }) {

    
    const handleFlag = async (receiptID) => {

        try {
            const newFlag = !viewReceiptData.receipt.flag
            await updateValueInDatabase("Receipts/" + receiptID + "/receipt", { flag: newFlag })
            viewReceiptData.receipt.flag = newFlag
            setReceiptData({ ...receiptData, [receiptID]: { receipt: viewReceiptData.receipt, items: viewReceiptData.items } })
        }
        catch (error) {
            console.log(error)
        }

    }
    
    return (

        <Modal
            closeIcon
            open={openViewReceiptModal}
            onClose={() => setOpenViewReceiptModal(false)}
            onOpen={() => setOpenViewReceiptModal(true)}
        >
            <Header > View Receipt or<a> Edit<Icon name="pencil"></Icon></a></Header>

            <Modal.Content>

                <Table celled>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Receipt Date</Table.HeaderCell>
                            <Table.HeaderCell>Company</Table.HeaderCell>
                            <Table.HeaderCell>Cash Amount</Table.HeaderCell>
                            <Table.HeaderCell>Gift Card Amount</Table.HeaderCell>
                            <Table.HeaderCell>Total Amount</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell> {/* Flag is a Boolean */}
                        </Table.Row>
                    </Table.Header>
                    
                    
                    <Table.Body>
                    {Object.keys(viewReceiptData).length > 0 &&

                        <Table.Row key={Object.keys(viewReceiptData)}>
                            <Table.Cell>{viewReceiptData.receipt.receiptDate}</Table.Cell>
                            <Table.Cell>{viewReceiptData.receipt.companyName}</Table.Cell>
                            <Table.Cell>{viewReceiptData.receipt.cashAmount}</Table.Cell>
                            <Table.Cell>{viewReceiptData.receipt.giftCardAmount}</Table.Cell>
                            <Table.Cell>{viewReceiptData.receipt.totalAmount}</Table.Cell>
                            <Table.Cell>{viewReceiptData.receipt.deliveryMethod}</Table.Cell>
                            <Table.Cell>
                                {viewReceiptData.receipt.flag ?
                                    <a href="#" onClick={() => handleFlag(viewReceiptData.receiptID)}>
                                        <Icon name="flag" color="red" />
                                    </a> :
                                    <a href="#" onClick={() => handleFlag(viewReceiptData.receiptID)}>
                                        <Icon name="flag outline" color="grey" />
                                    </a>
                                }
                            </Table.Cell>
                        </Table.Row>
                    
                    }
                </Table.Body> 
                </Table>
              
                <Table celled>
                <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Item Name</Table.HeaderCell>
                            <Table.HeaderCell>Qty</Table.HeaderCell>
                            <Table.HeaderCell>Item Price</Table.HeaderCell>
                            <Table.HeaderCell>Total Price</Table.HeaderCell> 
                        </Table.Row>
                    </Table.Header>
                    {viewReceiptData.items &&viewReceiptData.items.length > 0 &&
                    <Table.Body>
                    {viewReceiptData.items.map((item) => (

                        <Table.Row key={item.itemName}>
                            <Table.Cell>{item.itemName}</Table.Cell>
                            <Table.Cell>{item.qty}</Table.Cell>
                            <Table.Cell>{item.itemPrice}</Table.Cell>
                            <Table.Cell>{item.totalPrice}</Table.Cell>
                        </Table.Row>
                    )
                    )}
                    
                </Table.Body>
}
                </Table>

                <NotesSection viewReceiptData={viewReceiptData} receiptData={receiptData} setReceiptData={setReceiptData}/>

                <AttachementViewSection viewReceiptData={viewReceiptData} receiptData={receiptData} setReceiptData={setReceiptData} />



            </Modal.Content>

            <Modal.Actions>
                <Button color='red' onClick={() => setOpenViewReceiptModal(false)}>
                    <Icon name='remove' /> Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ViewReceipt