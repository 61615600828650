import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Change 'Switch' to 'Routes'
import { AuthProvider } from './Pages/AuthContext';
import Login from "./Pages/Login";
import Receipts from "./Pages/Receipts";
import Shipments from "./Pages/Shipments";
import Transactions from "./Pages/Transactions";
import AmazonOrders from "./Pages/AmazonOrders";
import AmazonListings from "./Pages/AmazonListings";
import GiftCards from "./Pages/GiftCards";
import Profitability from "./Pages/Profitability";
import NewReceipt_forMobile from "./Pages/NewReceipt_forMobile";

function App() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="/Receipts" element={<Receipts />} />
            <Route path="/Shipments" element={<Shipments />} />
            <Route path="/Transactions" element={<Transactions />} />
            {/* <Route path="/AmazonListings" element={<AmazonListings />} />
            <Route path="/AmazonOrders" element={<AmazonOrders />} /> */}
            <Route path="/GiftCards" element={<GiftCards />} />
            <Route path="/Profitability" element={<Profitability />} />
            <Route path="/m/NewReceipt" element={<NewReceipt_forMobile />} />

          </Routes>
        </Router>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;