import React, { useState, useEffect } from "react";
import { Grid, Menu, Dimmer, Loader } from "semantic-ui-react";
import { getAllfromDatabase } from "../Component/FirebaseHelperFunctions";
import TopNavBar from "../Component/TopNavBar";
import SideNavBar from "../Component/SideNavBar";
// import Profitability_Overall from "../Container/Profitability_Overall";
import ItemProfitability from "../Container/Profitability_ItemProfitability";
import OrderProfitability from "../Container/Profitability_OrderProfitability";
import { httpsCallable } from "firebase/functions"
import { myfunctions } from "../firebase";
import{ useNavigate } from "react-router-dom";
import { useAuth } from '../Pages/AuthContext';


function Profitability() {
  const [sectionSelected, setSectionSelected] = useState("Overall");
  const [receiptData, setReceiptData] = useState([]);
  const [allOrders, setAllOrders] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();

  const fetchData = async () => {
    const updateOrderReceiptMatch = httpsCallable(myfunctions, 'updateOrderReceiptMatch');

    try {
      setLoading(true);
      console.log("Updating Order Receipt Match...");
      await updateOrderReceiptMatch();
      console.log("Return Receipt Match...", updateOrderReceiptMatch);

      setReceiptData(await getAllfromDatabase('Receipts'));
      setAllOrders(await getAllfromDatabase('Orders'));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage(`Error fetching data: ${error.message}`);
      console.error(error);
    }
  };

  useEffect(() => {
    if (!user) {
      // User is not authenticated, navigate to the login page
      console.log("no user");
      navigate('/login');
      return;
    }else{
    fetchData();
    }
  }, []); 


  return (
    <Grid>
          <Dimmer active ={loading}>
      <Loader />
      </Dimmer>
    
      <Grid.Row>
        <TopNavBar />
      </Grid.Row>
      <Grid.Column width='2'>
        <SideNavBar pageName="Profitability" />
      </Grid.Column >
      <Grid.Column width='13'>

        <Menu attached='top' tabular>
          <Menu.Item
            name='WIP Overall'
            active={sectionSelected === "Overall"}
            onClick={() => setSectionSelected("Overall")}
          />
          <Menu.Item
            name='Item Profitability'
            active={sectionSelected === 'Item Profitability'}
            onClick={() => setSectionSelected("Item Profitability")}
          />

          <Menu.Item
            name='Order Profitability'
            active={sectionSelected === 'Order Profitability'}
            onClick={() => setSectionSelected("Order Profitability")}
          />
        </Menu>

        {sectionSelected === "Item Profitability" && 
        <ItemProfitability 
        setErrorMessage={setErrorMessage} 
        receiptData={receiptData} 
        setReceiptData={setReceiptData}
        allOrders={allOrders}
        />}

        {sectionSelected === "Order Profitability" && 
        <OrderProfitability 
        setErrorMessage={setErrorMessage} 
        receiptData={receiptData} 
        setReceiptData={setReceiptData}
        allOrders={allOrders}
        />}


      </Grid.Column>
      

    </Grid>

  );
}

export default Profitability;