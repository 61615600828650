import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { auth } from '../firebase';
import { useNavigate } from "react-router-dom";
import "../App.css"

function TopNavBar({ logoutbutton=true }) {

  const navigate = useNavigate();

  const handleLogout = async () => {
    console.log("Logged out")
        try {
          await auth.signOut(); // Sign out the currently authenticated user
          // You can perform additional actions after successful logout, e.g., navigate to a different page.
          navigate('/login');
        } catch (error) {
          console.error('Error logging out:', error);
          // Handle the error appropriately, e.g., show an error message to the user.
        }
  }

  return (
    <div className='navbar'>
      <p className='navbar-companyName'> Manual Automate! </p>
      {logoutbutton &&
        <Button
          style={{ backgroundColor: "#dd9933", color: "white" }}
          onClick={handleLogout}
          className='navbar-button'>
          Logout
        </Button>
      }
    </div>

  )

}

export default TopNavBar;