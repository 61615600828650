import React, { useState } from "react";
import { UploadToBucket } from "../Component/FirebaseHelperFunctions";
import { Modal, Input, Button } from "semantic-ui-react";
import { httpsCallable } from "firebase/functions"
import { myfunctions } from "../firebase";


function UploadBankStatements({ openUploadBankStatementsModal, setOpenUploadBankStatementsModal }) {


    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);



    const handleBOAFileChange = async (selectedFiles) => {
        try {
            await UploadToBucket("Transactions/", selectedFiles, ["BOA.csv"])
        } catch (error) {
            setErrorMessage("Error uploading BOA file to bucket:", error)
        }
    }

    const handleSephoraFileChange = async (selectedFiles) => {
        try {
            await UploadToBucket("Transactions/", selectedFiles, ["Sephora.csv"])
        } catch (error) {
            setErrorMessage("Error uploading Sephora file to bucket:", error)
        }

    }

    const handleRefresh = async () => {
        const updateItems = httpsCallable(myfunctions, "updateTransactions")
        setLoading(true)

        await updateItems()
            .then(() => {
                console.log("Function started")
                setLoading(false)
                setOpenUploadBankStatementsModal(false)
            }).catch((error) => {
                setLoading(false)
                console.log(error)
                setErrorMessage(error.message)
            })
    }



    return (
        <Modal
            size='fullscreen'
            onClose={() => setOpenUploadBankStatementsModal(false)}
            onOpen={() => setOpenUploadBankStatementsModal(true)}
            open={openUploadBankStatementsModal}
            closeOnDimmerClick={false}
            trigger={<Button color="teal" onClick={() => setOpenUploadBankStatementsModal(true)}>Upload Bank Statements</Button>}
        >
            <Modal.Header>Upload Bank Statements</Modal.Header>

            <Modal.Content>
                <h4>Upload Bank of America Statement</h4>
                <p>From the BOA account, Select the transactions time frame and download. The file has to be a CSV file </p>
                <Input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleBOAFileChange(e.target.files)}
                    fluid
                ></Input>

                <h4>Upload Sephora Statement</h4>
                <p>From Comenity account, go to Transactiona and Statements then under the statements tab, select the time frame and download. The file has to be a CSV file </p>
                <Input
                    type="file"
                    className="form-control"
                    onChange={(e) => handleSephoraFileChange(e.target.files)}
                    fluid
                ></Input>


            </Modal.Content>

            <Modal.Actions>
                <p style={{ color: "red" }}>{errorMessage}</p>
                <Button content="Close" onClick={() => setOpenUploadBankStatementsModal(false)} />
                <Button
                    loading={loading}
                    content="Run Refresh"
                    labelPosition='right'
                    icon='checkmark'
                    color="teal"
                    onClick={handleRefresh}
                />
            </Modal.Actions>

        </Modal>

    );
}

export default UploadBankStatements