import React, { useState, useEffect } from "react";
import NewReceipt from "../Container/NewReceipt";
import {  Grid, Menu } from "semantic-ui-react";
import { getAllfromDatabase } from "../Component/FirebaseHelperFunctions";
import TopNavBar from "../Component/TopNavBar";
import SideNavBar from "../Component/SideNavBar";
import Receipts_AllReceipts from "../Container/Receipts_AllReceipts";
import Receipts_MissingTransaction from "../Container/Receipts_MissingTransaction";
import Receipts_Flagged from "../Container/Receipts_Flagged";
import { useAuth } from '../Pages/AuthContext';
import{ useNavigate } from "react-router-dom";

function Receipts() {
  const [openNewReceiptModal, setOpenNewReceiptModal] = useState(false);
  const [sectionSelected, setSectionSelected] = useState("All Receipts");
  const [receiptData, setReceiptData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  
  const { user } = useAuth();
  const navigate = useNavigate();

  const fetchReceipts = async () => {
    try {
      setReceiptData(await getAllfromDatabase("Receipts"));


    } catch (error) {
      setErrorMessage("Error fetching data:", error);
    }
  };
  useEffect(() => {

    if (!user) {
      // User is not authenticated, navigate to the login page
      console.log("no user");
      navigate('/login');
      return;
    }else{
    fetchReceipts();
    }
  }, [user])

  return (
    <Grid>

      <Grid.Row>
        <TopNavBar />
      </Grid.Row>
      <Grid.Column width='2'>
        <SideNavBar pageName="Receipts" />
      </Grid.Column >
      <Grid.Column width='13'>

        <Menu attached='top' tabular>
          <Menu.Item
            name='All Receipts'
            active={sectionSelected === "All Receipts"}
            onClick={() => setSectionSelected("All Receipts")}
          />
          <Menu.Item
            name='Missing Transactions'
            active={sectionSelected === 'Missing Transactions'}
            onClick={() => setSectionSelected("Missing Transactions")}
          />

          <Menu.Item
            name='Flagged'
            active={sectionSelected === 'Flagged'}
            onClick={() => setSectionSelected("Flagged")}
          />
          <Menu.Item position='right'>
            <NewReceipt 
            openNewReceiptModal={openNewReceiptModal} 
            setOpenNewReceiptModal={setOpenNewReceiptModal}
            fetchReceipts={fetchReceipts} 
            />
          </Menu.Item>
        </Menu>

        {errorMessage && <p>{errorMessage}</p>}
        {sectionSelected === "All Receipts" && receiptData &&<Receipts_AllReceipts setErrorMessage={setErrorMessage} receiptData={receiptData} setReceiptData={setReceiptData} />}
        {sectionSelected === "Missing Transactions" && receiptData && <Receipts_MissingTransaction setErrorMessage={setErrorMessage} receiptData={receiptData} setReceiptData={setReceiptData}/>}
        {sectionSelected === "Flagged" && receiptData && <Receipts_Flagged setErrorMessage={setErrorMessage} receiptData={receiptData} setReceiptData={setReceiptData} />}

      </Grid.Column>
    </Grid>

  );
}

export default Receipts;