import React from "react";
import { Modal, Header, Icon, Button, Table, Input } from "semantic-ui-react";
import { updateValueInDatabase } from "../Component/FirebaseHelperFunctions";
import NotesSection from "../Component/NotesSection"
import AttachementViewSection from "../Component/AttachementViewSection"


function ShipmentsViewShipment({ viewReceiptData, receiptData, setReceiptData, openViewShipmentModal, setOpenViewShipmentModal, allItems }) {


    const handleFlag = async (receiptID) => {

        try {
            const newFlag = !viewReceiptData.receipt.flag
            await updateValueInDatabase("Receipts/" + receiptID + "/receipt", { flag: newFlag })
            viewReceiptData.receipt.flag = newFlag
            setReceiptData({ ...receiptData, [receiptID]: { receipt: viewReceiptData.receipt, items: viewReceiptData.items } })
        }
        catch (error) {
            console.log(error)
        }

    }

    const handledeliveryConfirmation = async (receiptID, ItemIndex) => {
        console.log("handledeliveryConfirmation", receiptID, ItemIndex)
        let newDeliveryConfirmation
        try {
            newDeliveryConfirmation = !receiptData[receiptID].items[ItemIndex].deliveryConfirmed
            await updateValueInDatabase("Receipts/" + receiptID + "/items/" + ItemIndex + "/", { deliveryConfirmed: newDeliveryConfirmation })
            let updatedViewReceiptData = { ...viewReceiptData }
            updatedViewReceiptData.items[ItemIndex].deliveryConfirmed = newDeliveryConfirmation
            setReceiptData({ ...receiptData, [receiptID]: { receipt: updatedViewReceiptData.receipt, items: updatedViewReceiptData.items } })
            // loop over all items and validate the delivery confirmation
            // if all items are true, set the delivery confirmation to Delivered
            let allItemsConfirmed = true;
            for (const item of viewReceiptData.items) {
                if (!item.deliveryConfirmed) {
                    allItemsConfirmed = false;
                    break;
                }
            }

            // If all items are confirmed, set the delivery confirmation to "true"
            let shipmentConfirmed = false;
            if (allItemsConfirmed) {
                shipmentConfirmed = true;
            }

            await updateValueInDatabase("Receipts/" + receiptID + "/receipt/", { deliveryConfirmed: shipmentConfirmed })
            updatedViewReceiptData.receipt.deliveryConfirmed = shipmentConfirmed
            console.log("viewReceiptData", updatedViewReceiptData)
            setReceiptData({ ...receiptData, [receiptID]: { receipt: updatedViewReceiptData.receipt, items: updatedViewReceiptData.items } })
        }
        catch (error) {
            console.log(error)
        }

    }

    const handleOpenItemPage = (itemName) => {
        let asin = ""
        for (const itemId in allItems) {
          if (allItems[itemId]['item-name'] === itemName) {
            asin = allItems[itemId]['product-id'];
          }
        }
        
        if (asin){
          window.open("https://www.amazon.com/dp/" + asin, '_blank').focus();
        }

      };

    return (

        <Modal
            closeIcon
            open={openViewShipmentModal}
            onClose={() => setOpenViewShipmentModal(false)}
            onOpen={() => setOpenViewShipmentModal(true)}
        >
            <Header > View Shipment </Header>

            <Modal.Content>

                <Table celled striped>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Receipt Date</Table.HeaderCell>
                            <Table.HeaderCell>Company</Table.HeaderCell>
                            <Table.HeaderCell>Notes</Table.HeaderCell>   {/* Notes is a list */}
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell> {/* Notes is a Boolean */}
                        </Table.Row>
                    </Table.Header>


                    <Table.Body>
                        {Object.keys(viewReceiptData).length > 0 &&

                            <Table.Row key={Object.keys(viewReceiptData)}>
                                <Table.Cell>{viewReceiptData.receipt.receiptDate}</Table.Cell>
                                <Table.Cell>{viewReceiptData.receipt.companyName}</Table.Cell>
                                <Table.Cell>
                                    {viewReceiptData.receipt.deliveryMethod}
                                    {viewReceiptData.receipt.deliveryConfirmed && <Icon name="check" color="green" style={{paddingLeft:"10px"}}/>}
                                </Table.Cell>
                                <Table.Cell>
                                    {viewReceiptData.receipt.flag ?
                                        <a href="#" onClick={() => handleFlag(viewReceiptData.receiptID)}>
                                            <Icon name="flag" color="red" />
                                        </a> :
                                        <a href="#" onClick={() => handleFlag(viewReceiptData.receiptID)}>
                                            <Icon name="flag outline" color="grey" />
                                        </a>
                                    }
                                </Table.Cell>
                            </Table.Row>

                        }
                    </Table.Body>
                </Table>

                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Item Name</Table.HeaderCell>
                            <Table.HeaderCell>Qty</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {viewReceiptData.items && viewReceiptData.items.length > 0 &&
                        <Table.Body>
                            {viewReceiptData.items.map((item, itemIndex) => (

                                <Table.Row key={item.itemName}>
                                     {item.itemName.startsWith("--") ?
                                        <Table.Cell><li>{item.itemName} - {item.details}</li></Table.Cell>:
                                    <Table.Cell>
                                        <li>
                                        <a href="#" onClick={() => handleOpenItemPage(item.itemName)}>{item.itemName}</a>
                                        </li>
                                        </Table.Cell>
}
                                    <Table.Cell>{item.qty}</Table.Cell>
                                    {item.deliveryConfirmed ?
                                        <Table.Cell>
                                            <Icon
                                                name="check"
                                                color="green"
                                                onClick={() => handledeliveryConfirmation(viewReceiptData.receiptID, itemIndex)} 
                                                />
                                        </Table.Cell>
                                        :
                                        <Table.Cell>
                                            <Icon
                                                name="check"
                                                color="grey"
                                                onClick={() => handledeliveryConfirmation(viewReceiptData.receiptID, itemIndex)} />
                                        </Table.Cell>
                                    }
                                </Table.Row>
                            )
                            )}

                        </Table.Body>
                    }
                </Table>

                <NotesSection viewReceiptData={viewReceiptData} receiptData={receiptData} setReceiptData={setReceiptData} />

                <AttachementViewSection viewReceiptData={viewReceiptData} receiptData={receiptData} setReceiptData={setReceiptData} />



            </Modal.Content>

            <Modal.Actions>
                <Button color='red' onClick={() => setOpenViewShipmentModal(false)}>
                    <Icon name='remove' /> Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ShipmentsViewShipment