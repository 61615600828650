import React, { useState, useEffect } from "react";
import {  Grid, Menu } from "semantic-ui-react";
import { getAllfromDatabase } from "../Component/FirebaseHelperFunctions";
import TopNavBar from "../Component/TopNavBar";
import SideNavBar from "../Component/SideNavBar";
import Shipments_ToEastCoast from "../Container/Shipments_toEastCoast";
import Shipments_ToWestCoast from "../Container/Shipments_toWestCoast";
import{ useNavigate } from "react-router-dom";
import { useAuth } from '../Pages/AuthContext';

function Shipments() {
  const [sectionSelected, setSectionSelected] = useState("To East Coast");
  const [receiptData, setReceiptData] = useState([]);
  const [allItems, setAllItems] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const { user } = useAuth();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setReceiptData(await getAllfromDatabase("Receipts"));
      setAllItems( await getAllfromDatabase("ItemsReport"))


    } catch (error) {
      setErrorMessage("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (!user) {
      // User is not authenticated, navigate to the login page
      console.log("no user");
      navigate('/login');
      return;
    }else{
    fetchData();
    }

  }, [])


  return (
    <Grid>
      <Grid.Row>
        <TopNavBar />
      </Grid.Row>
      <Grid.Column width='2'>
        <SideNavBar pageName="Shipments" />
      </Grid.Column >
      <Grid.Column width='13'>

        <Menu attached='top' tabular>
          <Menu.Item
            name='To East Coast'
            active={sectionSelected === "To East Coast"}
            onClick={() => setSectionSelected("To East Coast")}
          />
          <Menu.Item
            name='To West Coast'
            active={sectionSelected === 'To West Coast'}
            onClick={() => setSectionSelected("To West Coast")}
          />

        </Menu>

        {sectionSelected === "To East Coast" && 
        <Shipments_ToEastCoast 
        setErrorMessage={setErrorMessage} 
        receiptData={receiptData} 
        setReceiptData={setReceiptData}
        allItems={allItems}
        />}

        {sectionSelected === "To West Coast" && 
        <Shipments_ToWestCoast 
        setErrorMessage={setErrorMessage} 
        receiptData={receiptData} 
        setReceiptData={setReceiptData}
        allItems={allItems}
        />}








      </Grid.Column>


    </Grid>

  );
}

export default Shipments;