import React, { useState, useEffect } from "react";
import { Grid, Menu, Dimmer, Loader } from "semantic-ui-react";
import TopNavBar from "../Component/TopNavBar";
import SideNavBar from "../Component/SideNavBar";
import UploadBankStatements from "../Container/Transactions_UploadBankStatements";
import AllTransactions from "../Container/Transactions_AllTransactions";
import MissingReceipts from "../Container/Transactions_MissingReceipts";
import { getAllfromDatabase } from "../Component/FirebaseHelperFunctions";
import { useAuth } from '../Pages/AuthContext';
import{ useNavigate } from "react-router-dom";

function Transactions() {
  const [openUploadBankStatementsModal, setOpenUploadBankStatementsModal] = useState(false);
  const [sectionSelected, setSectionSelected] = useState("All Transactions");
  const [transactionsData, setTransactionsData] = useState({});
  const [allReceipts, setAllReceipts] = useState({})
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const { user } = useAuth();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setTransactionsData(await getAllfromDatabase("Transactions"));
      setAllReceipts(await getAllfromDatabase("Receipts"));
    } catch (error) {
      setErrorMessage("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or error
    }
  };
  useEffect(() => {
    
    if (!user) {
      // User is not authenticated, navigate to the login page
      console.log("no user");
      navigate('/login');
      return;
    }else{
    fetchData();
    }

  }, [])

  return (
    <Grid>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
      <Grid.Row>
        <TopNavBar />
      </Grid.Row>
      <Grid.Column width='2'>
        <SideNavBar pageName="Transactions" />
      </Grid.Column >
      <Grid.Column width='13'>

        <Menu attached='top' tabular>
          <Menu.Item
            name='All Transactions'
            active={sectionSelected === "All Transactions"}
            onClick={() => setSectionSelected("All Transactions")}
          />
          <Menu.Item
            name='Missing Receipts'
            active={sectionSelected === 'Missing Receipts'}
            onClick={() => setSectionSelected("Missing Receipts")}
          />
          <Menu.Item position='right'>
            <UploadBankStatements
              openUploadBankStatementsModal={openUploadBankStatementsModal}
              setOpenUploadBankStatementsModal={setOpenUploadBankStatementsModal}
            />
          </Menu.Item>
        </Menu>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {sectionSelected === "All Transactions" && (
              <AllTransactions
                transactionsData={transactionsData}
                setTransactionsData={setTransactionsData}
                allReceipts={allReceipts}
                setAllReceipts={setAllReceipts}
              />
            )}
            {sectionSelected === "Missing Receipts" && (
              <MissingReceipts
                transactionsData={transactionsData}
                setTransactionsData={setTransactionsData}
                allReceipts={allReceipts}
                setAllReceipts={setAllReceipts}
              />
            )}
          </>
        )}
      </Grid.Column>


    </Grid>

  );
}

export default Transactions;