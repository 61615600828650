import React from "react";
import { Grid, Input, Button } from "semantic-ui-react";
import SearchableDropdown from "./SearchableDropdown";
import { update } from "firebase/database";


const NewReceiptItemInputs = ({ newReceiptData, setNewReceiptData, itemNameOptions, handleDeleteItem, index }) => {
    const ItemExceptions = [
    "--Other Business Expense", 
    "--Shipping Expense (ex. PirateShip)", 
    "--Packing Expense (ex. Boxes)", 
    "--Items will Not Be Listed"];

    const handleItemsSelection = (value) => {
        const updatedItem = newReceiptData.items
        updatedItem[index].itemName = value;
        setNewReceiptData({ ...newReceiptData, items: updatedItem });
    }

    const refreshItemsTotal = () => {
        console.log("calculating item total")
        let itemsTotal = 0
        for (const item of newReceiptData.items) {
            itemsTotal = itemsTotal + parseFloat(item.totalPrice)
        }
        return(itemsTotal)
    }


    return (

        <Grid stackable>

            {ItemExceptions.includes(newReceiptData.items[index].itemName) ? (
                <>
                    <Grid.Column width='4'>
                        <SearchableDropdown
                            searchable={true}
                            dropdownLabel='Item Name'
                            inputOptions={itemNameOptions}
                            handleSelection={handleItemsSelection}
                            inputSubselectionLabel="item-name"
                            showOnlyUnique={true}
                            sortalphabetic={true}
                            selected={newReceiptData.items[index].itemName}
                        />

                    </Grid.Column>
                    <Grid.Column width='3'>
                        <Input
                            size='small'
                            placeholder='Details'
                            value={newReceiptData.items[index].details}
                            onChange={(e, { value }) => {
                                const updatedItems = [...newReceiptData.items];
                                updatedItems[index].details = value;
                                setNewReceiptData({ ...newReceiptData, items: updatedItems });
                            }}
                            onClick={(e) => e.stopPropagation()}
                            style={{ width: "100%" }}
                        />
                    </Grid.Column>
                </>
            ) :
                <Grid.Column width='7'>

                    <SearchableDropdown
                        searchable={true}
                        dropdownLabel='Item Name'
                        inputOptions={itemNameOptions}
                        handleSelection={handleItemsSelection}
                        inputSubselectionLabel="item-name"
                        showOnlyUnique={true}
                        sortalphabetic={true}
                        selected={newReceiptData.items[index].itemName}
                    />

                </Grid.Column>
            }




            <Grid.Column width='2'>
                <Input
                    type='number'
                    size='small'
                    label='Qty'
                    value={newReceiptData.items[index].qty}
                    onChange={(e, { value }) => {
                        const updatedItems = [...newReceiptData.items];
                        updatedItems[index].qty = value;
                        updatedItems[index].totalPrice = value * newReceiptData.items[index].itemPrice;
                        const updatedReceipt= newReceiptData.receipt
                        updatedReceipt.itemsTotal = refreshItemsTotal();
                        setNewReceiptData({ ...newReceiptData, items: updatedItems });
                    }}
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: "80%" }}
                />
            </Grid.Column>

            <Grid.Column width='2'>
                <Input
                    type='number'
                    size='small'
                    label='$/Unit'
                    value={newReceiptData.items[index].itemPrice}
                    onChange={(e, { value }) => {
                        const updatedItems = [...newReceiptData.items];
                        updatedItems[index].itemPrice = value;
                        updatedItems[index].totalPrice = parseFloat(value * newReceiptData.items[index].qty).toFixed(2);
                        const updatedReceipt= newReceiptData.receipt
                        updatedReceipt.itemsTotal = refreshItemsTotal();
                        setNewReceiptData({ ...newReceiptData, items: updatedItems });
                    }}
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: "70%" }}
                />
            </Grid.Column>

            <Grid.Column width='2'>
                <Input
                    type='number'
                    size='small'
                    label='Total'
                    value={newReceiptData.items[index].totalPrice}
                    onChange={(e, { value }) => {
                        const updatedItems = [...newReceiptData.items];
                        updatedItems[index].itemPrice = parseFloat(value / newReceiptData.items[index].qty).toFixed(2);
                        updatedItems[index].totalPrice = value;
                        const updatedReceipt= newReceiptData.receipt
                        updatedReceipt.itemsTotal = refreshItemsTotal();
                        setNewReceiptData({ ...newReceiptData, items: updatedItems });
                    }}
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: "70%" }}
                />
            </Grid.Column>

            <Grid.Column width='1'>
                <Button
                    icon="trash"
                    color="red"
                    onClick={() => handleDeleteItem(index)}
                    size="tiny"
                />
            </Grid.Column>
        </Grid>
    );
}

export default NewReceiptItemInputs;
