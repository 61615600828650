import React, {useEffect, useState} from "react";
import { Table, Icon } from "semantic-ui-react";
import ReceiptMatching from "./Transactions_ReceiptMatching";



function AllTransactions({ transactionsData,setTransactionsData,  allReceipts, setAllReceipts }) {

    const [displayTransactions, setDisplayTransactions] = useState([]);
    const [transactionForReciptSearch, setTransactionForReciptSearch] = useState({});
    const [openReceiptMatchingModal, setOpenReceiptMatchingModal] = useState(false);


    useEffect(() => {
        // Sort the transactions by transactionDate in descending order
        const sortedTransactions = Object.keys(transactionsData).sort((a, b) => new Date(transactionsData[b].Date) - new Date(transactionsData[a].Date));

        setDisplayTransactions(sortedTransactions);
    },[])


    return (

        <div>
            <ReceiptMatching 
            allReceipts = {allReceipts}
            setAllReceipts = {setAllReceipts}
            transactionsData = {transactionsData}
            setTransactionsData = {setTransactionsData}
            transactionForReciptSearch = {transactionForReciptSearch}
            openReceiptMatchingModal = {openReceiptMatchingModal}
            setOpenReceiptMatchingModal = {setOpenReceiptMatchingModal}
            />
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Transaction Card</Table.HeaderCell>
                    <Table.HeaderCell>Transaction Date</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>Notes</Table.HeaderCell>   {/* Notes is a list */}
                    <Table.HeaderCell></Table.HeaderCell> {/* Notes is a Boolean */}
                </Table.Row>
                </Table.Header>
                <Table.Body>
                    {displayTransactions.map((transactionID) => (

                        <Table.Row key={transactionID}>
                            <Table.Cell>{transactionsData[transactionID].Card}</Table.Cell>
                            <Table.Cell>{transactionsData[transactionID].Date}</Table.Cell>
                            <Table.Cell>{transactionsData[transactionID].Description}</Table.Cell>
                            <Table.Cell>{transactionsData[transactionID].Amount}</Table.Cell>
                            <Table.Cell>{transactionsData[transactionID].Notes}</Table.Cell>
                            <Table.Cell>
                                {transactionsData[transactionID]?.receipts && transactionsData[transactionID].receipts.length>0 ? 
                                <Icon 
                                name='file text' 
                                color="green"  
                                onClick={() => {
                                    setOpenReceiptMatchingModal(true)
                                    setTransactionForReciptSearch({...transactionsData[transactionID],transactionID:transactionID })
                                }
                                }/>
                                : 
                                <Icon 
                                name='file text outline' 
                                color="grey" 
                                onClick={() => {
                                    setOpenReceiptMatchingModal(true)
                                    setTransactionForReciptSearch({...transactionsData[transactionID],transactionID:transactionID })
                                }
                                }/>}

                                {transactionsData[transactionID]?.receipt && transactionsData[transactionID]?.receipt[0] === "Override"  ? 
                                <Icon name='tag' color="yellow" /> : 
                                null}

                                </Table.Cell>
                        </Table.Row>
                    )
                    )}
                </Table.Body>
            
        </Table>
        </div>
    )
}

export default AllTransactions