import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Button, Input, Grid } from 'semantic-ui-react'
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth"
import '../App.css'
import TopNavBar from "../Component/TopNavBar";

function Login() {
    const [loginDetails, setLoginDetails] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    const navigate = useNavigate();

    const handleLogin = async () => {

        try {
            setErrorMessage(null); // Reset error message before attempting login.
            await signInWithEmailAndPassword(auth, loginDetails.email, loginDetails.password)
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                navigate("/m/NewReceipt")
            }else{
                navigate("/Receipts")
            }
            
        } catch (error) {
            console.error(error);
            setErrorMessage(error.message); // Set the error message.
        }
    };

    return (
        <><TopNavBar logoutbutton={false} />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'teal' }}>

                <div style={{ textAlign: 'center', padding: '20px', background: 'teal', borderRadius: '8px' }}>

                    <div>
                        <Input
                            type="email"
                            placeholder="Email"
                            value={loginDetails.email}
                            onChange={(e) => setLoginDetails({ ...loginDetails, email: e.target.value })}
                            style={{ marginBottom: '10px', padding: '8px', borderRadius: '4px', width: '200px' }}
                        />
                    </div>

                    <div>
                        <Input
                            type="password"
                            placeholder="Password"
                            value={loginDetails.password}
                            onChange={(e) => setLoginDetails({ ...loginDetails, password: e.target.value })}
                            style={{ marginBottom: '10px', padding: '8px', borderRadius: '4px', width: '200px' }}
                        />
                    </div>

                    <div>
                        {errorMessage && <p>{errorMessage}</p>}
                    </div>

                    <div>
                        <Button
                            onClick={handleLogin}
                            style={{
                                padding: '8px 20px',
                                borderRadius: '4px',
                                background: 'teal',
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer'
                            }}
                        >
                            Login
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Login;
