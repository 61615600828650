import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, setPersistence, browserSessionPersistence } from "firebase/auth";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Set session persistence
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        // Continue with onAuthStateChanged
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
          setUser(authUser);
        });

        // Cleanup the subscription on unmount
        return () => unsubscribe();
      })
      .catch(error => {
        console.error("Error setting persistence:", error);
      });
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
